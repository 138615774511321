
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.02427 0.175736C9.25858 0.410051 9.25858 0.78995 9.02427 1.02426L4.22427 5.82426C3.98995 6.05858 3.61005 6.05858 3.37574 5.82426L0.975739 3.42426C0.741424 3.18995 0.741424 2.81005 0.975739 2.57574C1.21005 2.34142 1.58995 2.34142 1.82427 2.57574L3.8 4.55147L8.17574 0.175736C8.41005 -0.0585787 8.78995 -0.0585787 9.02427 0.175736Z" fill="#5B1FD3"/>
</svg>

  </template>

  <script>
  export default {
    name: 'IconCheck',
    inheritAttrs: true,
  }
  </script>
